import React from "react"
import jwt from "jwt-decode"
import { DateTime } from "luxon"

/**
 *
 * @param {{ children: JSX.Element | Array<JSX.Element>; history: H.History<unknown> }} props
 */
export const CheckLogin = (props) => {
  const token = localStorage.getItem("token")

  const { history } = props

  if (!token) {
    history.push("/Login")
    return null
  }

  try {
    const { exp } = jwt(token)

    if (DateTime.now() > DateTime.fromSeconds(exp)) {
      localStorage.removeItem("token")
      history.push("/Login")
      return null
    }
  } catch (error) {
    localStorage.removeItem("token")
    history.push("/Login")
    return null
  }

  const { children } = props

  return <>{children}</>
}
