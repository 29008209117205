import { createStore, combineReducers } from "redux"
import { messageReducer } from "./Messages/Message"

const rootReducer = combineReducers({
  message: messageReducer,
})

export function buildStore() {
  return createStore(rootReducer)
}
