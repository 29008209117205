export function addMessage(message) {
  return {
    type: "ADD_MESSAGE",
    message,
  }
}

export function removeMessage(message) {
  return {
    type: "REMOVE_MESSAGE",
    message,
  }
}

export function messageReducer(state = {}, action) {
  switch (action.type) {
    case "ADD_MESSAGE": {
      const { message } = action

      return { ...state, ...message }
    }
    case "REMOVE_MESSAGE": {
      return {}
    }
    default: {
      return state
    }
  }
}
