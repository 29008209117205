import React, { useEffect, useState } from "react"
import { Typography, Paper, Grid, FormControl, TextField, FormControlLabel, Checkbox } from "@material-ui/core"
import firebase from "firebase"
import { Form } from "./Form"

/**
 * @param {{ history: H.History<unknown> }} props
 */
export const Login = (props) => {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [passcode, setPasscode] = useState("")
  const [stage, setStage] = useState("password")
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null)
  const [verificationId, setVerificationId] = useState(null)
  const [config, setConfig] = useState(null)
  const [configured, setConfigured] = useState(false)
  const [resolver, setResolver] = useState(null)
  const [rememberDevice, setRememberDevice] = useState(false)

  const { history } = props

  useEffect(() => {
    async function fetchData() {
      const configResponse = await fetch("/auth", { method: "GET" })

      const config = await configResponse.json()

      setConfig(config)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!config) {
      return
    }

    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    } else {
      firebase.app() // if already initialized, use that one
    }

    setConfigured(true)
  }, [config])

  useEffect(() => {
    if (!configured) {
      return
    }

    const verifier = new firebase.auth.RecaptchaVerifier("recaptcha", {
      size: "invisible",
      callback: function (response) {},
    })

    setRecaptchaVerifier(verifier)

    return () => {
      verifier.clear()
      const wrapper = document.getElementById("recaptcha-wrapper")
      if (wrapper) {
        wrapper.innerHTML = '<div id="recaptcha"></div>'
      }
    }
  }, [configured])

  useEffect(() => {
    if (!configured) {
      return
    }

    const persistence = rememberDevice ? "local" : "session"

    firebase.auth().setPersistence(persistence)
  }, [configured, rememberDevice])

  async function codeVerification() {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, passcode)

    const multifactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)

    await resolver.resolveSignIn(multifactorAssertion)

    await login()
  }

  async function login() {
    const { currentUser } = firebase.auth()

    if (!currentUser) {
      throw Error("No user")
    }

    const idToken = await currentUser.getIdToken(true)

    const tokenResponse = await fetch("./auth/token", {
      method: "POST",
      body: JSON.stringify({ code: idToken }),
      headers: { "Content-Type": "application/json" },
    })

    const token = await tokenResponse.json()

    localStorage.setItem("token", token.token)

    history.push("/")
  }

  async function googleLogin() {
    let resolver
    firebase
      .auth()
      .signInWithEmailAndPassword(user, password)
      .then(login)
      .catch(function (error) {
        if (error.code == "auth/multi-factor-auth-required") {
          resolver = error.resolver
          // Ask user which second factor to use.
          if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
            const phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            }

            const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
            // Send SMS verification code
            return phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then(function (verificationId) {
                setResolver(resolver)
                setVerificationId(verificationId)
                setStage("passcode")
              })
          } else {
            // Unsupported second factor.
          }
        } else if (error.code == "auth/wrong-password") {
          // Handle other errors such as wrong password.
        }
      })
    //}
  }

  useEffect(() => {
    if (!configured) {
      return
    }

    let resolver

    firebase
      .auth()
      .getRedirectResult()
      .then(function (userCredential) {
        // User is not enrolled with a second factor and is successfully signed in.
        // ...
        console.log(userCredential)
      })
      .catch(function (error) {
        if (error.code == "auth/multi-factor-auth-required") {
          resolver = error.resolver
          // Ask user which second factor to use.
          if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
            var phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            }

            var phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
            // Send SMS verification code
            return phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then(function (verificationId) {
                setResolver(resolver)
                setVerificationId(verificationId)
                setStage("passcode")
              })
          } else {
            // Unsupported second factor.
          }
        } else if (error.code == "auth/wrong-password") {
          // Handle other errors such as wrong password.
        }
      })
  }, [configured])

  return (
    <Paper>
      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h4">Login</Typography>
        </Grid>
        <Grid item xs={12}>
          {stage === "passcode" ? (
            <Form submitAction={codeVerification} submitLabel="Continue">
              <FormControl margin="dense">
                <TextField
                  variant="outlined"
                  label="Passcode"
                  value={passcode}
                  onChange={(event) => setPasscode(event.target.value || "")}
                />
              </FormControl>
            </Form>
          ) : (
            <Form submitAction={googleLogin} submitLabel="Login">
              <FormControl margin="dense">
                <TextField
                  variant="outlined"
                  label="Email"
                  type="email"
                  value={user}
                  onChange={(event) => {
                    setUser(event.target.value || "")
                  }}
                />
              </FormControl>
              <FormControl margin="dense">
                <TextField
                  variant="outlined"
                  label="Password"
                  value={password}
                  type="password"
                  onChange={(event) => {
                    setPassword(event.target.value || "")
                  }}
                />
              </FormControl>
              <FormControl margin="normal" style={{ float: "right" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberDevice}
                      onChange={() => setRememberDevice(!rememberDevice)}
                      name="remember"
                      color="primary"
                    />
                  }
                  label="Remember Device"
                />
              </FormControl>
            </Form>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
