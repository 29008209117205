import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { addMessage } from "../Features/Messages/Message"

export function ComponentMessage(props) {
  const { children } = props

  const dispatch = useDispatch()

  useEffect(() => {
    function successMessage(event) {
      const { message } = event.detail

      dispatch(addMessage({ state: "success", text: message }))
    }

    function failureMessage(event) {
      const { message } = event.detail

      dispatch(addMessage({ state: "error", text: message }))
    }

    document.addEventListener("success-message", successMessage)
    document.addEventListener("failure-message", failureMessage)

    return () => {
      document.removeEventListener("success-message", successMessage)
      document.removeEventListener("failure-message", failureMessage)
    }
  }, [])

  return <>{children}</>
}
