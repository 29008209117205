import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Drawer,
  makeStyles,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from "@material-ui/core"
import { Menu as MenuIcon } from "@material-ui/icons"
import React, { useEffect, useState } from "react"
import { Switch, Route, BrowserRouter as Router, useHistory } from "react-router-dom"
import { CheckLogin } from "./CheckLogin"
import { Login } from "./Login"
import firebase from "firebase"
import { JwtLogin } from "./JwtLogin"

const useStyles = makeStyles((theme) => ({
  paper: {
    top: 56,
    width: 200,
  },
  drawerOpen: {
    width: 200,
    top: "56px !important",
    "& .MuiBackdrop-root": {
      top: 56,
    },
  },
  drawerClosed: {
    width: 0,
  },
  mainOpen: {
    paddingTop: 56,
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 200px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  mainClosed: {
    paddingTop: 56,
    width: "100%",
  },
}))

const routes = [
  {
    title: "Home",
    route: "/",
  },
  {
    title: "Create Recipe",
    route: "/Recipe/Create",
  },
  {
    title: "List Recipes",
    route: "/Recipe/List",
  },
  {
    title: "Search Recipes",
    route: "/Recipe/Search",
  },
  {
    title: "Create Meal Plan",
    route: "/MealPlan/Create",
  },
  {
    title: "View Meal Plans",
    route: "/MealPlan/List",
  },
  {
    title: "List Books",
    route: "/Book",
  },
  {
    title: "Create Book",
    route: "/Book/Create",
  },
]

const MenuItems = () => {
  const history = useHistory()

  return (
    <List>
      {routes.map((routeItem) => {
        const { title, route } = routeItem

        return (
          <ListItem button key={title} onClick={() => history.push(route)}>
            <ListItemText primary={title} />
          </ListItem>
        )
      })}
      <ListItem
        button
        key="logout"
        onClick={async () => {
          localStorage.removeItem("token")
          await firebase.app().auth().signOut()
          history.push("/")
        }}
      >
        <ListItemText primary="Log Out" />
      </ListItem>
    </List>
  )
}

export default () => {
  const [open, setOpen] = useState(true)

  const classes = useStyles()

  const history = useHistory()

  useEffect(() => {
    /**
     * @param {CustomEvent} event
     */
    function onEditMealPlan(event) {
      const mealplan = event.detail

      history.push(`/MealPlan/Edit/${mealplan.id}`)
    }

    /**
     * @param {CustomEvent} event
     */
    function onEditRecipe(event) {
      const recipe = event.detail

      history.push(`/Recipe/Edit/${recipe.id}`)
    }

    /**
     * @param {CustomEvent} event
     */
    function onEditBook(event) {
      const book = event.detail

      history.push(`/Book/Edit/${book.id}`)
    }

    document.addEventListener("edit-mealplan", onEditMealPlan)
    document.addEventListener("edit-recipe", onEditRecipe)
    document.addEventListener("edit-book", onEditBook)

    return () => {
      document.removeEventListener("edit-mealplan", onEditMealPlan)
      document.removeEventListener("edit-recipe", onEditRecipe)
      document.removeEventListener("edit-book", onEditBook)
    }
  }, [])

  return (
    <Switch>
      <Route
        path="/Login"
        exact
        render={({ history }) => (
          <Container maxWidth="xl" style={{ height: "100%" }}>
            <Grid
              container
              spacing={2}
              style={{ margin: 0, width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}
            >
              <Grid item xs={12} md={6}>
                <Login history={history} />
              </Grid>
            </Grid>
          </Container>
        )}
      />
      <Route
        path="/Dashboard"
        exact
        render={() => (
          <JwtLogin>
            <Container maxWidth="xl">
              <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                <Grid item xs={12}>
                  <current-meal-plan target="./mealplan/mealplan/current"></current-meal-plan>
                </Grid>
              </Grid>
            </Container>
          </JwtLogin>
        )}
      />
      <Route path="/">
        <AppBar position="fixed" style={{ height: 56 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ width: "100%", display: "flex" }}>
          <Hidden xsDown>
            <Drawer
              classes={{ paper: classes.paper }}
              className={open ? classes.drawerOpen : classes.drawerClosed}
              variant="persistent"
              anchor="left"
              open={open}
              onClose={() => setOpen(false)}
            >
              <MenuItems />
            </Drawer>
          </Hidden>
          <Hidden smUp>
            <Drawer
              classes={{ paper: classes.paper }}
              className={open ? classes.drawerOpen : classes.drawerClosed}
              variant="temporary"
              anchor="left"
              open={open}
              onClose={() => setOpen(false)}
            >
              <MenuItems />
            </Drawer>
          </Hidden>
          <main className={open ? classes.mainOpen : classes.mainClosed}>
            <Switch>
              <Route
                path="/"
                exact
                render={({ history }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography>Home</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h2">Current Meal Plan</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <current-meal-plan target="./mealplan/mealplan/current"></current-meal-plan>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Recipe/Create"
                exact
                render={({ history }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Create Recipe</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <create-recipe
                            target="./mealplan/recipe"
                            book-target="./mealplan/book/search"
                          ></create-recipe>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Recipe/List"
                exact
                render={({ history }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Recipes</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <list-recipe target="./mealplan/recipe"></list-recipe>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Recipe/Search"
                exact
                render={({ history }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Search Recipes</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <search-recipe target="./mealplan/recipe/search"></search-recipe>
                          <spotlight-recipe target="./mealplan/recipe" spotlight="highestrated"></spotlight-recipe>
                          <spotlight-recipe target="./mealplan/recipe" spotlight="mostused"></spotlight-recipe>
                          <spotlight-recipe target="./mealplan/recipe" spotlight="recentlyused"></spotlight-recipe>
                          <spotlight-recipe target="./mealplan/recipe" spotlight="newest"></spotlight-recipe>
                          <spotlight-recipe target="./mealplan/recipe" spotlight="notrecentlyused"></spotlight-recipe>
                          <spotlight-recipe target="./mealplan/recipe" spotlight="leastused"></spotlight-recipe>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/MealPlan/Create"
                exact
                render={({ history }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Create Meal Plan</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <create-meal-plan
                            target="./mealplan/mealplan"
                            search-target="./mealplan/recipe/search"
                          ></create-meal-plan>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/MealPlan/List"
                exact
                render={({ history }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">View Meal Plans</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <view-meal-plans target="./mealplan/mealplan"></view-meal-plans>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/MealPlan/Edit/:mealPlanId"
                exact
                render={({ history, match }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Edit Meal Plan</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <edit-meal-plan target={`./mealplan/mealplan/${match.params.mealPlanId}`}></edit-meal-plan>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Recipe/Edit/:recipeId"
                exact
                render={({ history, match }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Edit Recipe</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <edit-recipe target={`./mealplan/recipe/${match.params.recipeId}`}></edit-recipe>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Book"
                exact
                render={() => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">List Books</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <list-book target={`./mealplan/book`}></list-book>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Book/Edit/:bookId"
                exact
                render={({ match }) => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Edit Book</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <edit-book target={`./mealplan/book/${match.params.bookId}`}></edit-book>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
              <Route
                path="/Book/Create"
                exact
                render={() => (
                  <CheckLogin history={history}>
                    <Container maxWidth="xl">
                      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Create Book</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <create-book target={`./mealplan/book`}></create-book>
                        </Grid>
                      </Grid>
                    </Container>
                  </CheckLogin>
                )}
              />
            </Switch>
          </main>
        </div>
      </Route>
    </Switch>
  )
}
