import React, { useState } from "react"
import { FormGroup, FormControl, Button } from "@material-ui/core"
import { Loader } from "./Loader"
import { useDispatch } from "react-redux"
import { addMessage } from "../Features/Messages/Message"

export function Form(props) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { children, submitLabel, submitAction, successMessage, errorMessage } = props

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (loading) {
          return
        }

        try {
          setLoading(true)
          await submitAction()

          if (successMessage) {
            dispatch(addMessage({ state: "success", text: successMessage }))
          }
        } catch (error) {
          if (errorMessage) {
            dispatch(addMessage({ state: "error", text: errorMessage }))
          }
        } finally {
          setLoading(false)
        }
      }}
    >
      <FormGroup>
        {children}
        <FormControl margin="dense">
          <Button disabled={loading} color="primary" variant="contained" type="submit">
            {loading ? <Loader /> : submitLabel}
          </Button>
        </FormControl>
      </FormGroup>
    </form>
  )
}
