import App from "./Components/App"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { buildStore } from "./Features/Store"
import { Message } from "./Components/Message"
import { ComponentMessage } from "./Components/ComponentMessage"
import { BrowserRouter as Router } from "react-router-dom"
import { Auth } from "./Components/Auth"

const store = buildStore()

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Auth />
      <App />
    </Router>
    <ComponentMessage />
    <Message />
  </Provider>,
  document.getElementById("root")
)
