import React, { useEffect, useState } from "react"
import firebase from "firebase"

/**
 * @param {{ children: JSX.Element }} props
 */
export const JwtLogin = (props) => {
  const [config, setConfig] = useState(null)
  const [configured, setConfigured] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const configResponse = await fetch("/auth", { method: "GET" })

      const config = await configResponse.json()

      setConfig(config)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!config) {
      return
    }

    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    } else {
      firebase.app() // if already initialized, use that one
    }

    setConfigured(true)
  }, [config])

  useEffect(() => {
    async function getToken() {
      if (!configured) {
        return
      }

      const jwt = new URLSearchParams(window.location.search).get("jwt")

      if (!jwt) {
        return
      }

      await firebase.auth().signInWithCustomToken(jwt)

      await login()

      setLoggedIn(true)
    }

    getToken()
  }, [configured])

  async function login() {
    const { currentUser } = firebase.auth()

    if (!currentUser) {
      throw Error("No user")
    }

    const idToken = await currentUser.getIdToken(true)

    const tokenResponse = await fetch("./auth/token", {
      method: "POST",
      body: JSON.stringify({ code: idToken }),
      headers: { "Content-Type": "application/json" },
    })

    const token = await tokenResponse.json()

    localStorage.setItem("token", token.token)
  }

  return <>{loggedIn ? props.children : null}</>
}
