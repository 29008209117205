import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { removeMessage } from "../Features/Messages/Message"

function Alert(props) {
  const { children, ...rest } = props

  return (
    <MuiAlert elevation={6} variant="filled" {...rest}>
      {children}
    </MuiAlert>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

export function Message() {
  const classes = useStyles()

  const { text, state } = useSelector((state) => state.message)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(removeMessage({ text, state }))
  }

  if (!text) {
    return null
  }

  return (
    <div className={classes.root}>
      <Snackbar open={!!text} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  )
}
