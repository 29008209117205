import React from "react"
import firebase from "firebase"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { DateTime } from "luxon"
import jwt from "jwt-decode"
import { token } from "morgan"

async function login() {
  const { currentUser } = firebase.auth()

  if (!currentUser) {
    throw Error("No user")
  }

  const idToken = await currentUser.getIdToken(true)

  const tokenResponse = await fetch("./auth/token", {
    method: "POST",
    body: JSON.stringify({ code: idToken }),
    headers: { "Content-Type": "application/json" },
  })

  const token = await tokenResponse.json()

  localStorage.setItem("token", token.token)

  return token
}

window.authorise = async () => {
  const token = localStorage.getItem("token")

  if (!token) {
    return null
  }

  const { exp } = jwt(token)

  if (DateTime.now() <= DateTime.fromMillis(Number(exp) * 1000)) {
    return token
  }

  const { currentUser } = firebase.auth()

  if (!currentUser) {
    return null
  }

  return await login()
}

export const Auth = ({ children }) => {
  const [config, setConfig] = useState(null)
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      const configResponse = await fetch("/auth", { method: "GET" })

      const config = await configResponse.json()

      setConfig(config)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!config) {
      return
    }

    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    } else {
      firebase.app() // if already initialized, use that one
    }

    firebase
      .app()
      .auth()
      .onAuthStateChanged(async (user) => {
        if (!user) {
          return
        }

        await login()

        const tokenDetails = await user.getIdTokenResult()

        if (tokenDetails.claims.role === "dashboard") {
          history.push("/Dashboard")
          return
        }

        history.push("/")
      })
  }, [config])

  return <>{children}</>
}
